body {
  margin: 0;
  padding: 0;
  /* font-family: Verdana, Geneva, sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  overflow-x: hidden;
  background-color: #ffffff;
}

* {
  box-sizing: border-box;
  scrollbar-width: none;
}

a {
  text-decoration: none;
  outline: none;
  color: currentColor;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
button {
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
}

.button {
  font-size: 10pt;
  color: black;
  background-color: buttonface;
  text-align: center;
  padding: 2px 6px 3px;
  border-width: 2px;
  border-style: outset;
  border-color: buttonface;
  cursor: pointer;
  max-width: 250px;
}

.Toastify__toast-container {
  padding: 0;
  top: 1.5rem;
  position: absolute;
}

.Toastify__toast-container--top-center {
  top: 1rem;
  right: 0;
  left: auto;
  transform: none;
}

.Toastify__toast {
  border-radius: 8px;
  margin: 0 1rem 1rem;
  padding: 0.5rem;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  transition: filter 0.3s ease-out;
}

.Toastify__toast:hover {
  filter: brightness(1.1);
}

@media screen and (min-width: 844px) {
  .Toastify__toast {
    margin: 0 2rem 1rem;
  }
  .Toastify__toast-container {
    width: 384px;
    top: 2rem;
  }
}

.Toastify__toast-body {
  /* font-family: 'inherit'; */
  font-size: 0.75rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  margin: 0;
  line-height: 1.618em;
}

.Toastify__toast-body p {
  margin-top: 0.5rem;
}

.Toastify__toast-body span {
  color: hsla(0, 0%, 100%, 0.4);
}

.Toastify__toast-body span a {
  color: hsla(0, 0%, 100%, 0.6);
  text-decoration: underline;
  font-weight: bold;
}

.Toastify__toast--success {
  background: linear-gradient(to right, #07bc0c, #006609);
  /* box-shadow: 0px 4px 24px #0066094d; */
  color: white;
}
.Toastify__toast--dark {
  background: linear-gradient(to right, #343340, #343340);
  /* box-shadow: 0px 4px 24px #3433404d; */
  color: #ffffff;
}
.Toastify__toast--default {
  background: linear-gradient(to right, #f0f2f5, #d3d7e0);
  color: #343340;
  /* box-shadow: 0px 4px 24px #3433404d; */
}
.Toastify__toast--info {
  background: linear-gradient(to right, #3498db, #0a3b5c);
  /* box-shadow: 0px 4px 24px #0a3b5c4d; */
  color: #0a3b5c;
  color: white;
}
.Toastify__toast--warning {
  background: linear-gradient(to right, #f1970f, #a16900);
  /* box-shadow: 0px 4px 24px #a176004d; */
  color: #604e06;
  color: white;
}
.Toastify__toast--error {
  background: linear-gradient(to right, #e74c3c, #5c120a);
  /* box-shadow: 0px 4px 24px #5c120a4d; */
  color: white;
  font-weight: bold;
}
.Toastify__close-button--default {
  color: white;
}

@keyframes alert-company-overtime {
  0% {
    box-shadow: inset 0px 0px 0px 4px rgb(255 0 0);
  }
  100% {
    box-shadow: inset 0px 0px 0px 4px rgb(255 0 0 / 0%);
  }
}

@keyframes alert-company-overwarning {
  0% {
    box-shadow: inset 0px 0px 0px 4px rgb(255 128 0);
  }
  100% {
    box-shadow: inset 0px 0px 0px 4px rgb(255 128 0 / 0%);
  }
}

@keyframes alert-company-oversoon {
  0% {
    box-shadow: inset 0px 0px 0px 4px rgb(255 255 0);
  }
  100% {
    box-shadow: inset 0px 0px 0px 4px rgb(255 255 0 / 0%);
  }
}
@keyframes alert-company-overtime-outside {
  0% {
    box-shadow: 0px 0px 0px 4px rgb(255 0 0);
  }
  100% {
    box-shadow: 0px 0px 0px 4px rgb(255 0 0 / 0%);
  }
}

@keyframes alert-company-overwarning-outside {
  0% {
    box-shadow: 0px 0px 0px 4px rgb(255 128 0);
  }
  100% {
    box-shadow: 0px 0px 0px 4px rgb(255 128 0 / 0%);
  }
}

@keyframes alert-company-oversoon-outside {
  0% {
    box-shadow: 0px 0px 0px 4px rgb(255 255 0);
  }
  100% {
    box-shadow: 0px 0px 0px 4px rgb(255 255 0 / 0%);
  }
}

@keyframes alert-company-noestimate {
  0% {
    /* box-shadow: inset 0px 0px 0px 4px rgb(0 0 0 / 20%); */
  }
  100% {
    /* box-shadow: inset 0px 0px 0px 4px rgb(0 0 0 / 0%); */
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes shine {
  0% {
    opacity: 1;
    left: -100%;
  }
  100% {
    opacity: 1;
    left: 100%;
  }
}

@keyframes blink {
  0%,
  49% {
    opacity: 0;
  }
  50%,
  100% {
    opacity: 1;
  }
}
